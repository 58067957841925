/**
 * Komponente: service-slider
 */

.tjs-service-slider {
    --element-margin-bottom: 70px;
    --element-margin-bottom-mobile: 40px;

    &__content--container {
        background: transparent linear-gradient(293deg, #DCF2DE 0%, #E8F6FF 100%) no-repeat;
        border-radius: 40px;
        padding: rem(82px) 0;
    }

    &__content {
        &--row {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        &--col {
            flex-basis: 50%;
            @include col-side-paddings;
            @include col-mobile-bottom-paddings;

            &-text {
                text-align: left;
                padding-left: 18vw;
                padding-right: 7vw;

                @media (max-width: 1900px) {
                    padding-left: 10vw;
                }

                @include media-breakpoint-down(xxl) {
                    padding-left: 8vw;
                    padding-right: 4vw;
                }

                @include media-breakpoint-down(xl) {
                    padding-left: 7vw;
                    padding-right: 4vw;
                }

                h2 {
                    font-size: rem(46px);
                    line-height: rem(60px);
                    font-weight: 600;
                }

                &:has(blockquote) {
                    h2 {
                        color: $primary;
                        font-size: rem(18px);
                        line-height: rem(25px);
                        text-transform: uppercase;
                        font-weight: 800;
                    }

                    blockquote {
                        font-size: rem(46px);
                        line-height: rem(60px);
                        font-weight: 600;
                    }
                }
            }

            &-image {
                display: block;
            }

            &__mobile-button {
                display: none;
                text-align: center;
            }
        }
    }

    &__carousel {
        overflow: hidden;
        text-align: center;
        position: relative;
        padding-right: 0;
        padding-bottom: 30px;

        &--navigation {
            &-prev {
                position: absolute;
                top: calc(50% - 21px);
                position: absolute;
                z-index: 15;
                left: 0;
            }

            &-next {
                position: absolute;
                top: calc(50% - 21px);
                position: absolute;
                z-index: 15;
                right: 0;
            }

            &-prev, &-next {
                img {
                    width: rem(42px);
                    height: rem(42px);
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        &--nav-points {
            position: absolute;
            display: flex;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            &__point {
                width: rem(13px);
                height: rem(13px);
                background: $white;
                border-radius: 50%;
                border: 1px solid #363636;
                position: relative;

                &:not(:last-child) {
                    margin-right: rem(9px);
                }

                &:after {
                    content: "";
                    width: rem(9px);
                    height: rem(9px);
                    display: block;
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    background: $white;
                    border-radius: 50%;
                }

                &.swiper-pagination-bullet-active {
                    &:after {
                        background: $primary;
                    }
                }
            }
        }

        &--slide {
            background: $white;
            box-shadow: 0px 1px 2px #00000029;
            border-radius: 20px;
            padding: rem(30px);
            height: auto;
            margin-bottom: 5px;

            &__image {
                img {
                    width: 150px;
                    height: 150px;
                }
            }
        }
    }

    &.mobile {
        .tjs-wrapper {
            margin: 0 auto;
        }

        .tjs-service-slider {
            &__content--container {
                padding: 2rem 0 1rem 0;
            }

            &__col {
                flex-basis: 100%;

                h2 {
                    font-size: rem(38px);
                    line-height: rem(50px);
                }

                &:has(blockquote) {
                    h2 {
                        font-size: rem(16px);
                        line-height: rem(25px);
                    }

                    blockquote {
                        font-size: rem(38px);
                        line-height: rem(50px);
                    }
                }
            }

            &__content {
                padding: 1rem;

                &--col {
                    flex-basis: 100%;
                    @include col-side-paddings;

                    &__mobile-button {
                        display: block;
                    }

                    .desktop-button {
                        display: none;
                    }

                    &-text {
                        margin: 0 auto;
                    }
                }
            }

            &__carousel {
                margin-bottom: 2rem;
            }
        }
    }
}
