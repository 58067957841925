/**
 * Komponente: box-price
 */

.tjs-box-prices {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: rem(36px);
    align-items: center;

    @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }

    :nth-child(2) {
        .tjs-wrapper {
            padding: 4.5rem 2.5rem;
        }


        &.tjs-box-price__disturber {
            position: absolute;
            right: rem(10px);
            top: rem(10px);
            background: linear-gradient(to top right, transparent 0%, transparent 50%, $primary 50%, $primary 100%);
            border-radius: 0 20px 0 0;
            width: rem(170px);
            height: rem(100px);

            b {
                text-align: center;
                color: $white;
                transform: rotate(32deg);
                position: absolute;
                right: 4px;
                top: 24px;
                text-transform: uppercase;
            }
        }
    }

    :nth-child(1), :nth-child(3) {
        .tjs-wrapper {
            &:after {
                display: none!important;
            }
        }
    }

    &.white-boxes {
        .tjs-box-price {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 2px #00000029;
            border: 1px solid #DDDDDD;
            border-radius: 20px;

            .tjs-wrapper {
                background-color: $white;
            }

            h3, p, ul, li, .end-price, .regular-price {
                color: $font-main-color!important;
            }
        }
    }
}

.tjs-box-price {
    --element-margin-bottom: 90px;
    --element-margin-bottom-mobile: 50px;

    h3, p, ul, li {
        color: $white;
    }

    h3 span {
        color: $primary;
    }

    .tjs-wrapper {
        background-color: $col-background;
        border-radius: 20px;
        position: relative;
        padding: rem(40px);
    }

    &__disturber {
        position: absolute;
        right: rem(10px);
        top: rem(10px);
        background: linear-gradient(to top right, transparent 0%, transparent 50%, $primary 50%, $primary 100%);
        border-radius: 0 20px 0 0;
        width: rem(170px);
        height: rem(100px);

        b {
            text-align: center;
            color: $white;
            transform: rotate(32deg);
            position: absolute;
            right: 4px;
            top: 24px;
            text-transform: uppercase;
        }
    }

    &.column-3 {
        width: 30%;
    }

    &__border {
        &:after {
            content: "";
            border: 1px solid $primary;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            position: absolute;
            top: rem(10px);
            left: rem(10px);
            border-radius: 20px;
        }
    }

    &__content {
        &--header {
            text-align: center;
            border-bottom: 1px solid $primary;
            margin-bottom: 2rem;
            padding-bottom: 1rem;

            &-prices {
                .prices {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    align-items: center;
                    text-align: center;
                }

                .end-price {
                    color: $white;
                    font-size: rem(46px);
                    text-wrap: nowrap;

                    .currency {
                        color: $primary;
                    }
                }

                .regular-price {
                    color: $white;
                    font-size: rem(22px);
                    position: relative;

                    &:after {
                        content: "";
                        width: 46px;
                        height: 2px;
                        background: $primary;
                        position: absolute;
                        left: calc(50% - 27px);
                        bottom: 15px;
                        transform: rotate(-22deg);
                    }

                    &.no-price {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &.mobile {
        margin-right: rem(10px);
        margin-left: rem(10px);

        .tjs-box-price {

            &__content {
                &--header {
                    &-prices {
                        .end-price {
                            font-size: rem(28px);
                        }

                        .regular-price {
                            font-size: rem(18px);
                        }
                    }
                }
            }
        }
    }
}
