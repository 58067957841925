/**
 * Komponente: Newsletter
 */

.tjs-newsletter {
    $input-padding-y: .7rem;
    $input-padding-x: 1.5rem;

    font-family: 'Roboto Flex', sans-serif;
    padding: 2rem 0;
    color: var(--bs-white);
    overflow-x: hidden;

    &__content--container {
        background-color: var(--color-grey-dark-4);
        border-radius: 2rem;
        position: relative;
        display: flex;
        padding: 30px;
    }

    &__col {
        flex: 50%;
        padding-left: 0.75rem;
        padding-right: 0.75rem;

        &:nth-child(odd) {
            margin-right: rem(90px);
        }
    }

    &__stars {
        &--left {
            position: absolute;
            left: -5rem;
            top: -2rem;
            max-width: rem(70px);
            transform: scaleX(-1);
            opacity: 0.1;

            @include media-breakpoint-down(xxl) {
                left: rem(-30px);
            }

            @include media-breakpoint-down(xl) {
                left: 0;
            }
        }

        &--right {
            position: absolute;
            right: -5rem;
            bottom: -2rem;
            max-width: rem(70px);
            opacity: 0.1;

            @include media-breakpoint-down(xxl) {
                right: rem(-30px);
            }

            @include media-breakpoint-down(xl) {
                right: 0;
            }
        }
    }

    p {
        color: $white;
    }

    h2 {
        text-transform: uppercase;
        font-variation-settings: "wght" 500;
        letter-spacing: 1.8px;
        display: inline-flex;
        margin-bottom: 1rem;
        color: var(--color-green);
        font-size: inherit;
    }

    .input-group-text {
        border: none;
        padding: $input-padding-y $input-padding-x;
        font-variation-settings: "wght" 500;
        background-color: var(--color-green);
        color: var(--bs-white);
        flex: 1;

        &.outside {
            display: none;
        }
    }

    &__input-wrapper {
        display: flex;
        align-items: center;

        .form-floating {
            flex: 5;
        }
    }

    input {
        --joda-use: input();
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &:focus {
            outline: none;
            box-shadow: none;
            border-color: var(--bs-border-color);
        }
        &::placeholder {
            color: var(--color-placeholder);
        }
    }

    &.mobile {
        .tjs-newsletter {
            &__input-wrapper {
                flex-direction: column;
            }

            &__content--container {
                flex-direction: column;
            }

            &__col {
                flex: 100%;

                &:nth-child(odd) {
                    margin-right: 0;
                }
            }

            &__stars {
                &--left {
                    position: absolute;
                    left: rem(10px);
                    top: rem(10px);
                    max-width: rem(50px);
                }

                &--right {
                    position: absolute;
                    right: rem(10px);
                    bottom: rem(10px);
                    max-width: rem(50px);
                    transform: scaleX(-1);
                }
            }
        }

        input {
            padding: $input-padding-y .9rem;
            border-top-right-radius: var(--bs-border-radius) !important;
            border-bottom-right-radius: var(--bs-border-radius) !important;
        }

        .input-group-text {
            &.inside {
                display: none;
            }
            &.outside {
                display: block;
                width: 100%;
                text-align: center;
                margin-top: .7rem;
            }
        }
    }

    &.dark {
        background-color: var(--color-grey-dark);

        .tjs__newsletter__icon {
            filter: opacity(0.3);
        }
    }
}
