/**
 * Komponente: box-color
 */

.tjs-box-color-container {
    --element-margin-bottom: 70px;
    --element-margin-bottom-mobile: 80px;

    h2 {
        font-size: 25px;
        margin-bottom: 40px;
    }

    &__inner {
        .swiper-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: rem(50px);
            grid-auto-rows: 1fr;

            @include media-breakpoint-down(lg) {
                grid-template-columns: 1fr;
                grid-gap: 0;
            }

            @include media-breakpoint-down(md) {
                display: flex;
                grid-template-columns: unset;
                grid-gap: unset;
                grid-auto-rows: unset;
            }

            .tjs-box-color__disturber {
                position: absolute;
                right: 0;
                top: 0;
                background: linear-gradient(to top right, transparent 0%, transparent 50%, $primary 50%, $primary 100%);
                border-radius: 0 20px 0 0;
                width: rem(170px);
                height: rem(100px);

                b {
                    text-align: center;
                    color: $white;
                    transform: rotate(32deg);
                    position: absolute;
                    right: 3px;
                    top: 13px;
                    text-transform: uppercase;
                }
            }

            .swiper-slide {
                height: auto;
            }
        }
    }

    &.mobile {
        .tjs-box-color {
            &__carousel {
                &--nav-points {
                    display: flex;
                }
            }
        }
    }
}

.tjs-box-color {
    --element-margin-bottom: 90px;
    --element-margin-bottom-mobile: 50px;

    .tjs-wrapper {
        background: transparent linear-gradient(151deg, #E8F6FF 0%, #ECF2DC 100%) 0% 0% no-repeat padding-box;
        border: 1px solid $element-border-color;
        border-radius: 20px;
        position: relative;
        padding: rem(20px);
        height: 100%;
    }

    &__carousel {
        &--nav-points {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 20px;
            display: none;

            &__point {
                width: rem(13px);
                height: rem(13px);
                background: $white;
                border-radius: 50%;
                border: 1px solid #363636;
                position: relative;
                display: block;

                &:not(:last-child) {
                    margin-right: rem(9px);
                }

                &:after {
                    content: "";
                    width: rem(9px);
                    height: rem(9px);
                    display: block;
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    background: $white;
                    border-radius: 50%;
                }

                &.swiper-pagination-bullet-active {
                    &:after {
                        background: $primary;
                    }
                }
            }
        }
    }

    &__content {
        height: 100%;
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 30px;
            margin-bottom: rem(17px);
            font-weight: 500;
        }

        blockquote {
            font-size: 18px;
            color: $primary;
            margin-bottom: 0;
            font-weight: 500;
        }

        img {
            max-width: 200px;
            width: 100%;
            margin: 0 auto;
        }

        b {
            font-weight: 600!important;
        }

        ul {
            margin-top: 20px;
            margin-bottom: auto;
        }

        .btn {
            width: 100%;
            border-radius: 10px;
            padding-top: 10px!important;
            padding-bottom: 10px!important;
            font-weight: 800;

            a {
                text-decoration: none;

                &:hover {
                    color: unset;
                }
            }

            &:hover {
                a {
                    color: $white;
                }
            }
        }
    }
}
