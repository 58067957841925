/**
 * Komponente: contact-form
 */

.tjs-contact-form {
    --element-margin-bottom: 70px;
    --element-margin-bottom-mobile: 40px;

    font-family: 'Roboto Flex', sans-serif;
    position: relative;
    margin-top: 65px;
    text-align: left;

    &__content--container {
        &-inner {
            display: flex;
        }
    }

    &__col {
        flex: 50%;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }

    input, textarea, select {
        --joda-use: "input()";
    }

    .form-check {
        margin: 0;
    }

    textarea {
        height: 100px!important;
    }

    form {
        color: var(--color-placeholder);
        font-variation-settings: "weight" 350;

        input {
            &:focus {
                outline: none;
                box-shadow: none;
                border-color: var(--color-placeholder);
            }
        }

        .form-control {
            border-color: var(--color-placeholder);
        }

        .form-check-input {
            &:checked {
                background-color: var(--color-green);
                border-color: var(--color-green);
            }
        }

        a {
            color: var(--color-green);
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }

        button {
            width: 100%;
            background-color: var(--color-green);
            color: var(--bs-white);
            border: none;
            padding: 0.6rem 1rem;
            border-radius: 0.5rem;
        }

        p {
            font-size: 1rem;
            margin: unset;
            width: 100%;
        }
    }

    &__form {
        background: #fff;
        border-radius: 20px;
        padding: rem(30px) rem(30px) rem(15px);
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
    }

    &__text {
        h3 {
            font-size: rem(25px);
            width: 75%;
            margin-bottom: rem(50px);
        }
        p {
            font-size: rem(25px);
            margin: 0 0 rem(30px);
        }
        ul {
            li {
                display: flex;
                align-items: center;
                text-align: left;
                margin-bottom: 1rem;

                i {
                    border: 1px solid $primary;
                    border-radius: 50%;
                    padding: 10px 16px;
                    color: $primary;
                    font-size: 25px;
                    margin-right: 1rem;
                }
            }
        }
    }

    &.mobile {
        .tjs-contact-form {
            &__content--container {
                padding-top: rem(20px);
                padding-bottom: rem(20px);

                &-inner {
                    flex-direction: column;
                }
            }

            &__col {
                flex: 100%;
            }

            &__form {
                margin-bottom: 2rem;
            }

            &__text {
                width: 100%;
            }
        }

        form {
            padding: 1.2rem;
        }
    }
}
