/**
 * Komponente: key-figures
 */

.tjs-key-figures {
    --element-margin-bottom: 70px;
    --element-margin-bottom-mobile: 40px;

    &__content--container {
        background: $element-border-color;
        border-radius: 40px;
        padding: rem(65px) 0;
        position: relative;
    }

    &__content {
        z-index: 2;
        position: inherit;

        &--bg-image {
            position: absolute;

            width: 100%;
            height: 100%;
            top: 0;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        &--row {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        &--col {
            flex-basis: 25%;
            text-align: center;
            @include col-side-paddings;
            @include col-mobile-bottom-paddings;

            &:not(&:last-child) {
                margin-right: auto;
            }

            &-text {
                text-align: left;
            }

            &-image {
                display: block;
            }

            &__mobile-button {
                display: none;
                text-align: center;
            }

            &__figure {
                color: $primary;
                font-size: rem(60px);
                font-weight: 500;
            }

            &__text {
                font-size: rem(24px);
                font-weight: 500;
            }
        }
    }

    &.mobile {
        .tjs-wrapper {
            margin: 0 auto;
        }

        .tjs-key-figures {
            &__content--container {
                padding: 2rem 0 1rem 0;
            }

            &__content {
                &--col {
                    flex-basis: 50%;
                    @include col-side-paddings;

                    &__mobile-button {
                        display: block;
                    }

                    .desktop-button {
                        display: none;
                    }

                    &__figure {
                        font-size: rem(30px);
                    }

                    &__text {
                        font-size: rem(16px);
                    }
                }
            }
        }
    }
}
