/**
 * Komponente: icon-catchphrases
 */

.tjs-icon-catchphrases {
    --element-margin-bottom: 90px;
    --element-margin-bottom-mobile: 50px;

    @include col-side-paddings;

    h2 {
        font-size: 25px;
        line-height: 33px;
        font-weight: 600;
    }

    p {
        font-size: 25px;
        line-height: 33px;
    }

    &__text {
        text-align: center;
        font-size: rem(25px);
        margin-bottom: rem(40px);
    }

    &__items {
        display: flex;


        &--item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;

            &-icon {
                border: 1px solid $element-border-color;
                border-radius: 50%;
                display: flex;
                align-items: center;
                padding: rem(25px);
                margin-bottom: rem(12px);
                width: 110px;
                height: 110px;
                justify-content: center;

                img {
                    width: 58px;
                    height: auto;
                }
            }

            &-text {
                font-size:  rem(18px);
                text-align: center;

                em {
                    font-style: normal;
                }
            }
        }
    }

    .btn {
        font-size: 18px;
        border-radius: 10px;
        padding-top: 10px!important;
        padding-bottom: 10px!important;
        font-weight: 800;
    }

    &.mobile {
        h2, p {
            font-size: 23px;
            line-height: 30px;
        }

        .tjs-icon-catchphrases {
            &__items {
                flex-flow: wrap;

                @include media-breakpoint-down(md) {
                    flex-flow: unset;
                    flex-direction: column;
                    margin: unset;
                    margin-bottom: rem(12px);
                }

                &--item {
                    flex: 50%;
                    margin: unset;
                    margin-bottom: rem(24px);
                    flex-direction: row;

                    @include media-breakpoint-down(md) {
                        margin-bottom: rem(12px);
                    }

                    &-icon {
                        margin-bottom: 0;
                        margin-right: rem(24px);
                    }

                    &-text {
                        text-align: left;
                    }
                }
            }
        }

        &.mobile-2-rows {
            .tjs-icon-catchphrases {
                &__items {
                    flex-flow: wrap;
                    flex-direction: row;

                    &--item {
                        flex: 50%;
                        margin-bottom: unset;
                        flex-direction: column;

                        @include media-breakpoint-down(md) {
                            margin-bottom: rem(30px);
                        }

                        &-icon {
                            margin-bottom: 10px;
                            margin-right: 0;
                            text-align: center;
                        }

                        &-text {
                            text-align: center;
                        }
                    }
                }
            }
        }

        .btn {
            padding-left: 10px !important;
            padding-right: 10px !important;
            width: 100%;
        }
    }
}
