/**
 * Komponente: footer
 */

.tjs-footer {
    background-color: $footer-bg;
    color: var(--bs-white);
    font-family: 'Roboto Flex', sans-serif;
    padding: 90px 0 1rem 0;
    position: relative;
    min-height: 250px;

    .tjs__wrapper {
        position: relative;
    }

    &__content--container {
        display: flex;
    }

    &__col {
        &--double {
            flex: 1;

            &-inner {
                max-width: rem(400px);
            }
        }

        &--inner {
            display: flex;
        }
    }

    &__shape {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;

        $shape-width: min(100vw, 500px);

        &:nth-child(1) {
            border-width: calc($shape-width / 2) 0 0 $shape-width;
            border-color: transparent transparent transparent var(--color-grey-dark-2);
        }

        &:nth-child(2) {
            border-width: calc($shape-width / 4) 0 0 calc($shape-width / 2);
            border-color: transparent transparent transparent var(--color-grey-dark-3);
        }
    }

    strong, a, p, span {
        color: $white;
    }

    hr {
        margin-top: 4rem;
        border-color: var(--color-grey-muted);
    }

    a {
        text-decoration: none;
        color: inherit;
        &:hover {
            text-decoration: underline;
        }
    }

    &__logo {
        width: 200px;
        margin-bottom: 1rem;
    }

    &__links {
        margin-right: rem(64px);

        h3 {
            display: inline-flex;
            text-transform: uppercase;
            margin-bottom: 1rem;
            font-variation-settings: 'wght' 300;
            font-size: 1.1rem;
            color: $white;
        }

        ul {
            list-style: none;
            padding-left: 0;

            li:not(:nth-last-child(1)) {
                margin-bottom: rem(10px);
            }
        }

        a {
            display: inline-flex;
            gap: .4rem;
            color: $white;

            img {
                width: 1rem;
            }
        }
    }

    &__legal {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: var(--color-grey-muted);
        font-variation-settings: 'wght' 200;

        span {
            color: var(--color-grey-muted);
        }

        &__links {
            display: flex;
            flex-direction: row;

            span {
                &:not(:last-of-type) {
                    &::after {
                        content: "|";
                        margin: 0 1rem;
                    }
                }
            }
        }
    }

    &.mobile {
        .tjs-footer {
            &__col {
                &--inner {
                    flex-direction: column;
                }
            }

            &__links {
                flex: 1;
                margin-bottom: 30px;

                strong {
                    margin-top: 1rem;
                    margin-bottom: .5rem;
                }

                &__mobile__images {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 2rem;

                    & > div {
                        flex-direction: row;
                        justify-content: space-evenly;
                        gap: 2rem;
                    }

                    a {
                        span {
                            display: none;
                        }

                        img {
                            width: 2rem;
                        }
                    }
                }
            }

            &__legal {
                flex-direction: column;
                text-align: center;

                &___links {
                    flex-direction: column;
                    order: -1;
                    margin-bottom: 1rem;

                    span {
                        &::after {
                            content: none;
                        }
                    }
                }
            }

            &__content--container {
                display: flex;
                flex-direction: column;
            }

            &__col {
                &--double {
                    margin-bottom: rem(30px);

                    &-inner {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
