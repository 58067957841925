/**
 * Komponente: image-slider
 */

.tjs-image-slider {
    --element-margin-bottom: 70px;
    --element-margin-bottom-mobile: 40px;

    &__content--container {
        background: transparent linear-gradient(0deg, #EDEDED 0%, #FFFFFF 15%, #FFFFFF 85%, #EDEDED 100%) 0% 0% no-repeat padding-box;
        border-radius: 40px;
        padding: rem(82px) 0;
        position: relative;
    }

    &__content {
        &--headline {
            --element-margin-bottom: 50px;
            --element-margin-bottom-mobile: 30px;

            text-align: center;
            padding-left: 1rem;
            padding-right: 1rem;
        }

        &--row {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
    }

    &__carousel {
        overflow: hidden;
        text-align: center;
        position: relative;
        padding-bottom: 30px;

        &--navigation {
            &-prev {
                position: absolute;
                top: calc(50% - 21px);
                position: absolute;
                z-index: 1;
                left: 20px;
            }

            &-next {
                position: absolute;
                top: calc(50% - 21px);
                position: absolute;
                z-index: 1;
                right: 20px;
            }

            &-prev, &-next {
                img {
                    width: rem(42px);
                    height: rem(42px);
                }
            }
        }

        &--nav-points {
            position: absolute;
            display: flex;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            &__point {
                width: rem(13px);
                height: rem(13px);
                background: $white;
                border-radius: 50%;
                border: 1px solid #363636;
                position: relative;

                &:not(:last-child) {
                    margin-right: rem(9px);
                }

                &:after {
                    content: "";
                    width: rem(9px);
                    height: rem(9px);
                    display: block;
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    background: $white;
                    border-radius: 50%;
                }

                &.active {
                    &:after {
                        background: $primary;
                    }
                }
            }
        }

        &--slides {
            display: flex;
            overflow-x: scroll;
            padding-bottom: 5px;
        }

        &--slide {
            border-radius: 20px;
            padding: rem(30px);
            min-width: 100%;

            img {
                width: 55%;
            }

            &__image {
                margin-bottom: 2rem;
            }

            &__links {
                .btn-secondary {
                    background: $slider-button-color;
                    font-size: rem(18px);
                    height: rem(50px);
                    line-height: rem(20px);
                    margin-right: rem(30px);

                    &:hover {
                        background: $font-main-color;
                    }
                }
                .btn-outline-secondary {
                    color: $slider-button-color;
                    border-color: $slider-button-color;
                    font-size: rem(18px);
                    height: rem(50px);
                    line-height: rem(20px);

                    &:hover {
                        background: $slider-button-color;
                        border-color: $slider-button-color;
                    }
                }

                i {
                    color: white;
                    margin-left: 5px;
                }
            }
        }
    }

    &__stars {
        &--left {
            position: absolute;
            left: 9rem;
            top: 9rem;
            max-width: 15rem;
            opacity: 1;
            z-index: 5;

            @include media-breakpoint-down(xxl) {
                left: 6rem;
                top: 6rem;
                max-width: 10rem;
            }

            @include media-breakpoint-down(xl) {
                left: 3rem;
                top: 3rem;
                max-width: 8rem;
            }
        }

        &--right {
            position: absolute;
            right: 9rem;
            bottom: 9rem;
            max-width: 15rem;
            transform: scale(-1);
            opacity: 1;
            z-index: 5;

            @include media-breakpoint-down(xxl) {
                right: 6rem;
                bottom: 6rem;
                max-width: 10rem;
            }

            @include media-breakpoint-down(xl) {
                right: 3rem;
                bottom: 3rem;
                max-width: 8rem;
            }
        }
    }

    &.mobile {
        .tjs-wrapper {
            margin: 0 auto;
        }

        .tjs-image-slider {
            &__content--container {
                padding: 2.5rem 0 0;
            }

            &__col {
                flex-basis: 100%;
            }

            &__content {
                &--col {
                    flex-basis: 100%;
                    @include col-side-paddings;
                }
            }

            &__carousel {
                margin-bottom: 2rem;
                padding-bottom: 0;

                &--nav-points {
                    display: none;
                }

                &--slide {
                    flex: 0 0 100%;
                    min-width: auto;

                    &__links {
                        .btn-secondary {
                            margin-bottom: .5rem;
                        }

                        .btn {
                            width: 100%;
                        }
                    }

                    &__image {
                        margin-bottom: 5rem;
                    }

                    img {
                        width: 90%;
                    }
                }
            }

            &__stars {
                &--left, &--right {
                    display: none;
                }
            }
        }
    }
}
