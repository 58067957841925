.tjs-disturber-button {
    --element-margin-bottom: 70px;
    --element-margin-bottom-mobile: 40px;

    .btn {
        font-size: 18px;
        font-weight: 800;
        margin-top: 10px;
        border-radius: 10px;
        padding-top: 10px!important;
        padding-bottom: 10px!important;
    }

    &.mobile {
    }
}
