.tjs-disturber {
    --element-margin-bottom: 70px;
    --element-margin-bottom-mobile: 40px;

    font-size: rem(20px);
    line-height: rem(25px);
    background: #263238;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: rem(5px) 0 rem(6px);

    &__content {
        text-align: center;

        p {
            margin: 0;
            color: $white;
        }

        b {
            font-weight: 700!important;
        }
    }

    &.mobile {
        .tjs-disturber {
            &__content {
                max-width: 80vw!important;
                padding: rem(10px) 0;
            }
        }
    }
}
