/**
 * Komponente: box-white
 */

.tjs-box-white-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-gap: rem(60px);

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
}

.tjs-box-white {
    --element-margin-bottom: 90px;
    --element-margin-bottom-mobile: 50px;

    .tjs-wrapper {
        background-color: $white;
        border: 1px solid $element-border-color;
        border-radius: 20px;
        position: relative;
        padding: rem(40px);
        height: 100%;
    }

    &__content {
        h2 {
            font-size: rem(24px);
            margin-bottom: rem(17px);
        }

        b {
            font-weight: 600!important;
        }

        .btn {
            a {
                text-decoration: none;

                &:hover {
                    color: unset;
                }
            }

            &:hover {
                a {
                    color: $white;
                }
            }
        }
    }

    &.mobile {
        margin-right: rem(10px);
        margin-left: rem(10px);

        .tjs-box-white {
            margin-bottom: rem(36px);
        }
    }
}
