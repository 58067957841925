/**
 * Komponente: header
 */

.tjs-header1 {
    --element-margin-bottom: 50px;
    --element-margin-bottom-mobile: 0;

    background: transparent linear-gradient(118deg, #E8F6FF 0%, #ECF2DC 100%) 0% 0% no-repeat padding-box;
    padding: rem(15px) 0;
    border-bottom: 1px solid #e5e5e5;
    padding-top: var(--t-hero-top-space, 0px);
    min-height: calc(100vh - 110px);
    display: flex;
    align-items: center;

    &.bg-into-white {
        background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box, linear-gradient(103deg, #E8F6FF 0%, #EBF3E1 54%, #ECF2DC 100%) 0% 0% no-repeat padding-box;
        border-bottom: none;

        :not(:has(img)) {
            .tjs-header1 {
                &__hero--col-text {
                    padding: 0;
                    text-align: left;
                }
            }
        }
    }

    &.overlap-elements {
        margin-bottom: -70px;
    }

    &.no-bottom-margin {
        margin-bottom: 0;
    }

    &.center-text {
        h1 {
            text-align: center;
        }
        margin-bottom: 0;

        p {
            text-align: center;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    h1 {
        margin-bottom: 30px;
        font-size: rem(46px);
    }

    p {
        font-size: rem(24px);
        width: 80%;
        margin: 0 auto;

    }

    .btn {
         font-size: 18px;
         font-weight: 800;
         margin-top: 20px;
         border-radius: 10px;
         padding-top: 10px!important;
         padding-bottom: 10px!important;
     }

    &.text-left {
        p {
            margin: unset;
        }
    }

    &__hero {
        --element-margin-bottom: 70px;
        --element-margin-bottom-mobile: 40px;

        &--row {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        &--col {
            flex-basis: 50%;
            @include col-side-paddings;

            &-text {
                text-align: left;
            }

            &-image {
                display: block;
            }
        }
    }

    &.decreased-height {
        padding-bottom: rem(50px);
        min-height: unset;
    }

    :not(:has(img)) {
        .tjs-header1 {
            &__hero {
                margin-bottom: 2rem;
                margin-top: -2rem;
            }

            &__hero--row {
                display: block;
            }

            &__hero--col-text {
                text-align: center;
            }
        }
    }

    &.mobile {
        padding-top: 50px;
        padding-bottom: 0px;
        min-height: auto;
        display: block;
        align-items: unset;

        .tjs-header1 {
            &__hero {
                &--col {
                    flex-basis: 100%;

                    &-text {
                        text-align: center;
                    }

                    &-image {
                        display: none;
                    }
                }
            }
        }

        h1 {
            margin-bottom: 20px;
            font-size: rem(36px);
        }

        p {
            font-size: rem(18px);
            width: 100%;
        }
    }
}
