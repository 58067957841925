/**
 * Komponente: google-map
 */

.tjs-google-map {
    position: relative;
    margin-bottom: -7px;

    iframe {
        height: 610px;
    }

    .tjs-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &__address {
        width: 300px;
        background: $white;
        border-radius: 10px;
        padding: 20px 20px 4px;
        bottom: 30px;
        position: absolute;

        h1 {
            display: none;
        }

        img {
            display: block;
            height: 45px;
            width: auto;
            margin-bottom: 15px;
        }

        p {
            font-size: rem(16px);
            line-height: 21px;
        }
    }

    &.mobile {
        .tjs--google-map {

        }
    }
}
