

.btn {
    padding: rem(14px) rem(37px)!important;
}

.btn-primary {
    color: $white!important;
}

.btn-secondary {
    color: $primary!important;
    background: transparent!important;
    border: 1px solid $primary!important;

    &:hover {
        background: $primary!important;
        color: $white!important;
    }
}

.btn-outline-primary {
    &:hover {
        color: $white!important;
    }
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    @include media-breakpoint-up(xxl) {
        padding: 0 rem(115px)!important;
    }
}

b {
    font-weight: 500!important;
}

.row {
    @include media-breakpoint-down(md) {
        padding: 0 18px!important;
    }
}
