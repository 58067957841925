/**
 * Komponente: cols-2-bg
 */

.tjs-cols-2-bg {
    --element-margin-bottom: 70px;
    --element-margin-bottom-mobile: 40px;

    &.reversed-rows {
        .tjs-cols-2-bg__col {
            &:nth-child(1) {
                order: 2;
            }
        }
    }

    &.no-disturber {
        .tjs-cols-2-bg__disturber {
            display: none!important;
        }
    }

    .tjs-wrapper {
        background-color: $col-background;
        border-radius: 20px;
        position: relative;
        padding: rem(40px);
    }

    &--special {
        border-radius: 40px!important;
        margin-bottom: 8rem!important;
        margin-top: 12rem!important;
    }

    &__border {
        &:after {
            content: "";
            border: 1px solid $primary;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            position: absolute;
            top: rem(10px);
            left: rem(10px);
            border-radius: 20px;
        }
    }

    &__disturber {
        position: absolute;
        right: rem(10px);
        top: rem(10px);
        background: linear-gradient(to top right, transparent 0%, transparent 50%, $primary 50%, $primary 100%);
        border-radius: 0 20px 0 0;
        width: rem(170px);
        height: rem(100px);
        z-index: 10;

        b {
            text-align: center;
            color: $white;
            transform: rotate(32deg);
            position: absolute;
            right: -0.125rem;
            top: rem(12px);
        }

        &-image {
            position: absolute;
            top: rem(-124px);
            right: rem(80px);
            z-index: 5;

            img {
                height: rem(214px);
            }
        }

        &-stars {
            &--left {
                position: absolute;
                left: rem(-100px);
                bottom: rem(20px);
                max-width: rem(110px);

                @media (max-width: 1600px) {
                    left: rem(-50px);
                    bottom: rem(-80px);
                }

                @media (max-width: 1400px) {
                    bottom: rem(20px);
                }
            }

            &--right {
                position: absolute;
                right: rem(-100px);
                top: rem(20px);
                max-width: rem(110px);
                transform: scaleX(-1);

                @media (max-width: 1600px) {
                    right: rem(-50px);
                    top: rem(-80px);
                }

                @media (max-width: 1400px) {
                    top: rem(20px);
                }
            }
        }
    }

    &__row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 60px;
        align-items: center;
        padding: rem(10px) 0;
        position: inherit;
        z-index: 2;

        &--special {
            padding: rem(50px) 0;
        }
    }

    &__col {
        h3 {
            @include h3;
            color: $primary;
        }

        &--image {
            text-align: center;

            img {
                width: 100%;
                max-width: rem(375px);
            }
        }

        &--button-content {
            .btn {
                font-size: 18px;
                border-radius: 10px;
                padding-top: 10px!important;
                padding-bottom: 10px!important;
                font-weight: 800;
                width: 100%;
            }
        }

        p {
            @include p;
            color: $white;
        }

        a {
            text-decoration: none;
            @include p;
        }

        h2 {
            color: $white;
            font-weight: 400;
        }

        .btn {
            a {
                color: white;

                &:hover {
                    color: unset;
                }
            }
        }
    }

    &.mobile {
        margin-right: rem(10px);
        margin-left: rem(10px);

        .tjs-cols-2-bg {
            &--special {
                margin-top: 8rem!important;
                margin-bottom: 5rem!important;
            }

            &__row {
                grid-template-columns: 1fr;
                grid-gap: 20px;

                &--special {
                    .tjs-cols-2-bg__col--text-content {
                        text-align: center;
                    }
                }
            }

            &__col {
                &--image {
                    order: 1;
                }

                &--text-content {
                    order: 2;
                }

                &--button-content {
                    order: 3;
                }
            }

            &__disturber {
                &-image {
                    position: absolute;
                    top: rem(-86px);
                    right: 0;
                    z-index: 5;

                    img {
                        height: rem(150px);
                    }
                }

                &-stars {
                    &--left {
                        position: absolute;
                        left: rem(10px);
                        bottom: rem(10px);
                        max-width: rem(50px);
                    }

                    &--right {
                        position: absolute;
                        right: rem(10px);
                        top: rem(10px);
                        max-width: rem(50px);
                        transform: scaleX(-1);
                    }
                }
            }
        }
    }
}
