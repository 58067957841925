/**
 * Komponente: cols-2
 */

@import "cols-2-bg";
.tjs-cols-2 {
    --element-margin-bottom: 70px;
    --element-margin-bottom-mobile: 40px;

    &.reversed-rows {
        .tjs-cols-2__col {
            &:nth-child(1) {
                order: 2;
            }
        }
    }

    &.hide-headline {
        h2 {
            display: none;
        }
    }

    &.images-full-width {
        img {
            max-width: unset!important;
        }
    }

    .tjs-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 60px;
        align-items: center;
    }

    h2 {
        margin-bottom: 1rem;
        font-weight: 600;
    }

    &__col {
        h3 {
            @include h3;
        }

        &--image {
            text-align: center;

            img {
                width: 100%;
                height: auto;
                max-width: 375px;
            }
        }

        p {
            @include p;
        }

        a {
            text-decoration: none;
            @include p;
        }
    }

    &.mobile {
        .tjs-wrapper {
            grid-template-columns: 1fr;
            grid-gap: 20px;
        }

        .tjs-cols-2 {
            &__col {
                &--image {
                    order: 1;

                    img {
                        max-width: 280px;
                        margin-bottom: 28px;
                    }
                }

                &--text-content {
                    order: 2;
                }
            }
        }
    }
}
