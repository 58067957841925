@import "bootstrap/scss/bootstrap";

.tjs__e-card-default {

    border: 1px solid var(--t-section-border-color);
    border-radius: var(--t-section-border-radius);
    overflow: hidden;
    background-color: white;
    height: 100%;
    min-width: 300px;

    &.card {
        height: 100%;
    }

    --aspect-ratio: var(--layout-aspect-ratio, 16 / 9);





    .tjs__e-card-default__img {
        &>slot:empty {
            display: none;
        }
        &> slot {
            width: 100%;
            aspect-ratio: var(--aspect-ratio);
            display: block;
            overflow: hidden;
            img {

                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

    }

    .tjs__e-card-default__body {
        @extend  .p-4;
        height: 100%;
    }
}
