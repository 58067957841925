/**
 * Komponente: header
 */

.tjs-header2 {
    --element-margin-bottom: 50px;
    --element-margin-bottom-mobile: 0;
    background: transparent linear-gradient(118deg, #E8F6FF 0%, #ECF2DC 100%) 0% 0% no-repeat padding-box;
    padding: rem(15px) 0;
    padding-top: 180px;
    border-bottom: 1px solid #e5e5e5;

    &.no-bottom-margin {
        margin-bottom: 0;
    }

    h1 {
        margin-bottom: 30px;
        font-size: rem(42px);
        line-height: rem(55px);
    }

    img {
        max-width: 100%;
        height: auto;

        @include media-breakpoint-down(md) {
            max-width: 80%;
            margin-top: -20px;
        }
    }

    .btn {
        font-size: 18px;
        font-weight: 800;
        margin-top: 10px;
        border-radius: 10px;
        padding-top: 10px!important;
        padding-bottom: 10px!important;
    }

    &.mobile {
        padding-top: 30px;

        h1 {
            margin-bottom: 25px;
            font-size: rem(30px);
            line-height: rem(39px);
        }

        .btn {
            padding-left: 10px !important;
            padding-right: 10px !important;
            width: 100%;
        }
    }
}
