/**
 * Komponente: Contact
 */

.tjs-contact {
    font-family: 'Roboto Flex', sans-serif;
    position: relative;
    margin-bottom: rem(-80px);

    &__content--container {
        background: transparent linear-gradient(293deg, #DCF2DE 0%, #E8F6FF 100%) no-repeat;
        border-radius: 40px;
        padding-top: rem(55px);
        padding-bottom: rem(55px);

        &-inner {
            display: flex;
        }
    }

    &__col {
        flex: 50%;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }

    input, textarea, select {
        --joda-use: "input()";
    }

    .form-check {
        margin: 0;
    }

    &__newsletter {
        &--callback-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & > div {
                display: flex;
                align-items: center;
                gap: 1rem;
            }
        }

        &--text {
            h2 {
                font-size: rem(46px);
                line-height: rem(60px);
                font-weight: 600;
            }

            &:has(blockquote) {
                h2 {
                    color: $primary;
                    font-size: rem(18px);
                    line-height: rem(25px);
                    text-transform: uppercase;
                }

                blockquote {
                    font-size: rem(46px);
                    line-height: rem(60px);
                    font-weight: 600;
                }
            }

            > strong {
                font-variation-settings: "wght" 500;
                color: var(--color-green);
                text-transform: uppercase;
                letter-spacing: 1.8px;
            }


        }

        &--privacy-wrapper {
            margin: 2rem 0;
        }
    }

    form {
        background: var(--bs-white);
        padding: 2rem;
        border-radius: 1rem;
        color: var(--color-placeholder);
        font-variation-settings: "wght" 350;

        input {
            &:focus {
                outline: none;
                box-shadow: none;
                border-color: var(--color-placeholder);
            }
        }

        .form-control {
            border-color: var(--color-placeholder);
        }

        .form-check-input {
            &:checked {
                background-color: var(--color-green);
                border-color: var(--color-green);
            }
        }

        a {
            color: var(--color-green);
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }

        button {
            width: 100%;
            background-color: var(--color-green);
            color: var(--bs-white);
            border: none;
            padding: 0.6rem 1rem;
            border-radius: 0.5rem;
        }
    }

    &.mobile {
        .tjs-contact {
            &__newsletter {
                &--callback-wrapper {
                    flex-direction: column;
                    align-items: start;
                }

                &--text {
                    h2 {
                        font-size: rem(38px);
                        line-height: rem(50px);
                    }

                    &:has(blockquote) {
                        h2 {
                            color: $primary;
                            font-size: rem(16px);
                            line-height: rem(25px);
                            text-transform: uppercase;
                        }

                        blockquote {
                            font-size: rem(38px);
                            line-height: rem(50px);
                        }
                    }

                    > strong {
                        font-variation-settings: "wght" 500;
                        color: var(--color-green);
                        text-transform: uppercase;
                        letter-spacing: 1.8px;
                    }
                }
            }

            &__content--container {
                &-inner {
                    flex-direction: column;
                }
            }

            &__col {
                flex: 100%;
            }
        }

        form {
            padding: 1.2rem;
        }
    }

    &.overlap-next {
        margin-bottom: -5rem;

        & ~ section {
            padding-top: 10rem;
        }
    }
}
