/**
 * Komponente: image-slider
 */

.tjs-filter-images {
    --element-margin-bottom: 70px;
    --element-margin-bottom-mobile: 40px;

    text-align: center;

    h2 {
        margin-bottom: rem(50px);
        text-transform: uppercase;
    }

    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
    }

    &__filters {
        margin-bottom: rem(50px);

        &--filter {
            border: 1px solid $filter-color;
            border-radius: 50px;
            padding: rem(10px) rem(40px);
            margin: 0 rem(10px);

            &.selected {
                background: $filter-color;
                color: $white;
            }

            &:hover {
                cursor: pointer;
                background: $filter-color;
                color: $white;
            }
        }
    }

    &__item {
        position: relative;
        flex: calc(50% - 15px);
        border: 1px solid $filter-item-border;
        max-width: calc(50% - 15px);

        @media (hover: none) {
            a {
                background: yellow;
            }
        }

        /* smartphones, touchscreens */
        @media (hover: none) and (pointer: coarse) {
            &.hover .tjs-filter-images__item--content {
                transform: rotateY(180deg);
            }
        }

        @media (hover: hover) {
            &:hover .tjs-filter-images__item--content {
                transform: rotateY(180deg);
            }
        }

        &:hover {
            cursor: pointer;
        }

        &.is-animated {
            animation: 0.5s zoom-in;
        }

        @keyframes zoom-in {
            0% {
                transform: scale(.1);
            }
            100% {
                transform: none;
            }
        }

        &--content {
            transition: 0.6s;
            transform-style: preserve-3d;

            position: relative;
        }

        .front, .back {
            backface-visibility: hidden;
        }

        .front {
            z-index: 2;
            /* for firefox 31 */
            transform: rotateY(0deg);
            background: cornflowerBlue;
        }

        .back {
            transform: rotateY(180deg);
        }

        img {
            width: 100%;
            height: auto;
        }

        &--information {
            background: $primary;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            h3, p, ul, li {
                color: $white;
            }
        }
    }

    &.mobile {
        .tjs-wrapper {
            margin: 0 auto;
        }

        .tjs-filter-images {
            &__filters {
                margin-bottom: rem(30px);
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
                grid-gap: 1rem;
            }

            h2 {
                margin-bottom: rem(30px);
            }

            &__list {
                gap: 20px;
            }

            &__item {
                flex: 100%;
                max-width: unset;
            }
        }
    }
}
