/**
 * Komponente: customer-reviews
 */

.tjs-customer-reviews {
    --element-margin-bottom: 70px;
    --element-margin-bottom-mobile: 40px;

    &__content {
        &--headline {
            text-align: center;
            --element-margin-bottom: 50px;
            --element-margin-bottom-mobile: 30px;
        }

        &--row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 60px;
            align-items: center;
        }
    }

    &__review {
        overflow: hidden;
        text-align: center;
        position: relative;
        background: $white;
        border: 1px solid $element-border-color;
        border-radius: 20px;
        padding: rem(30px);
        height: 100%;

        &--stars {
            margin-bottom: rem(30px);
        }

        &--text {
            margin-bottom: auto;
            font: italic normal normal 20px/27px Roboto;
        }

        &--author {
            h3 {
                margin-top: 5px;
                font-size: rem(20px);
            }
            a {
                color: $primary;
            }

            .bi-star-fill {
                color: $primary!important;
            }
        }
    }

    &.mobile {
        .tjs-customer-reviews {
            &__content {
                &--row {
                    grid-template-columns: 1fr;
                    grid-gap: 20px;
                }
            }
        }
    }
}
