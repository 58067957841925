/**
 * Komponente: customer-reviews-slider
 */

.tjs-customer-reviews-slider {
    --element-margin-bottom: 180px;
    --element-margin-bottom-mobile: 130px;

    &__content--container {
        background: $element-border-color;
        border-radius: 40px;
        padding: rem(82px) 0;
    }

    &__content {
        &--headline {
            text-align: center;
            --element-margin-bottom: 50px;
            --element-margin-bottom-mobile: 30px;
        }

        &--row {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
    }

    &__carousel {
        overflow: hidden;
        text-align: center;
        position: relative;
        padding-bottom: 30px;

        &--navigation {
            &-prev {
                position: absolute;
                top: calc(50% - 21px);
                position: absolute;
                z-index: 15;
                left: 20px;
            }

            &-next {
                position: absolute;
                top: calc(50% - 21px);
                position: absolute;
                z-index: 15;
                right: 20px;
            }

            &-prev, &-next {
                img {
                    width: rem(42px);
                    height: rem(42px);
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        &--nav-points {
            position: absolute;
            display: flex;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            &__point {
                width: rem(13px);
                height: rem(13px);
                background: $white;
                border-radius: 50%;
                border: 1px solid #363636;
                position: relative;

                &:not(:last-child) {
                    margin-right: rem(9px);
                }

                &:after {
                    content: "";
                    width: rem(9px);
                    height: rem(9px);
                    display: block;
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    background: $white;
                    border-radius: 50%;
                }

                &.swiper-pagination-bullet-active {
                    &:after {
                        background: $primary;
                    }
                }
            }
        }

        &--slides {
            display: flex;
            overflow-x: scroll;
            padding-bottom: 5px;
        }

        &--slide {
            background: $white;
            box-shadow: 0px 1px 2px #00000029;
            border-radius: 20px;
            padding: rem(30px);
            min-width: rem(425px);
            max-width: 500px;
            display: flex;
            flex-direction: column;
            height: auto;

            &:first-child {
                margin-left: 35px;
            }

            &__stars {
                margin-bottom: rem(30px);
            }

            &__text {
                margin-bottom: auto;
                font: italic normal normal 20px/27px Roboto;
            }

            &__author {
                h3 {
                    margin-top: 5px;
                    font-size: rem(20px);
                }
                a {
                    color: $primary;
                }

                .bi-star-fill {
                    color: $primary!important;
                }
            }
        }
    }

    &.mobile {
        .tjs-wrapper {
            margin: 0 auto;
        }

        .tjs-customer-reviews-slider {
            &__content--container {
                padding: 2.5rem 0 0;
            }

            &__col {
                flex-basis: 100%;
            }

            &__content {
                &--col {
                    flex-basis: 100%;
                    @include col-side-paddings;
                }
            }

            &__carousel {
                margin-bottom: 2rem;

                &--slide {
                    flex: 0 0 100%;
                    min-width: auto;

                    &:first-child {
                        margin-left: 0;
                        scroll-margin-left: 0;
                    }
                }
            }
        }
    }
}
