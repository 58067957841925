/**
 * Komponente: cards
 */

/** könnte alternativ auch als "--cards" Variable im Template stehen, um automatisiert mit der Anzahl der Karten befüllt zu werden */
$cards: 4;
$cardHeightMobile: rem(360px);
$cardTopPadding: 1rem;

.tjs-cards-slider {
    --element-margin-bottom: 90px;
    --element-margin-bottom-mobile: 50px;

    .tjs-wrapper {
        display: flex;
    }

    &__col {
        flex-basis: 50%;
        @include col-side-paddings;
        @include col-mobile-bottom-paddings;
    }

    img {
        width: 100%;
        height: auto;
    }

    h3 {
        @include h3;
        margin-top: rem(45px);
    }

    &__text {
        position: sticky;
        top: rem(30px);

        h2 {
            font-size: rem(46px);
            line-height: rem(60px);
            font-weight: 600;
        }

        &:has(blockquote) {
            h2 {
                color: $primary;
                font-size: rem(18px);
                line-height: rem(25px);
                text-transform: uppercase;
            }

            blockquote {
                font-size: rem(46px);
                line-height: rem(60px);
                font-weight: 600;
            }
        }

        .btn {
            font-size: 18px;
            border-radius: 10px;
            padding-top: 10px!important;
            padding-bottom: 10px!important;
            font-weight: 800;
        }
    }

    &__list {
        list-style: none;
        padding-left: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat($cards, auto);

        @for $i from 1 through $cards {
            &-item[data-card-id="#{$i}"] {
                --index: #{$i};
                --indexRem: #{$i}rem;
            }
        }

        &-item {
            position: sticky;
            top: rem(30px);

            &:not(:first-child) {
                padding-top: var(--indexRem);
                margin-top: calc(var(--index) * -0.5rem);
            }

            &:nth-child(even) {
                transform: rotate(-0.003turn);
            }

            &-body {
                box-sizing: border-box;
                padding: rem(20px);
                border-radius: 20px;
                height: auto;
                transition: all 0.5s;
                border: 1px solid $element-border-color;
                background: $white;

                &--text {
                    font-size: rem(18px);
                }

                &--row {
                    display: flex;

                    &__col {
                        flex-basis: 50%;
                    }
                }
            }
        }
    }

    &.mobile {
        .tjs-wrapper {
            flex-direction: column;
        }

        .tjs-cards-slider {
            &__col {
                flex-basis: 100%;
            }

            &__text {
                h2 {
                    font-size: rem(38px);
                    line-height: rem(50px);
                }

                &:has(blockquote) {
                    h2 {
                        color: $primary;
                        font-size: rem(16px);
                        line-height: rem(25px);
                        text-transform: uppercase;
                    }

                    blockquote {
                        font-size: rem(38px);
                        line-height: rem(50px);
                    }
                }
            }
        }
    }
}
