ul {
    padding-left: 0;
}
.list-check-circle, ul.list-check-circle {
    padding-left: 1.25rem;

    ul {
        padding-left: 1.26rem;

    }

    li {
        font-size: inherit;
        line-height: inherit;
        padding-left: 1rem;
        list-style-type: none;
        position: relative;

        &:not(&:nth-last-child(1)) {
            margin-bottom: rem(10px);
        }

        &:before {
            content: " ";
            display: block;
            border: solid 12px $primary;
            border-radius: 12px;
            height: 0;
            width: 0;
            position: absolute;
            left: rem(-20px);
            top: 40%;
            margin-top: rem(-9px);
        }
        &:after {
            content: " ";
            display: block;
            width: rem(7px);
            height: rem(14px);
            border: solid #fff;
            border-width: 0 0.2em 0.2em 0;
            position: absolute;
            left: rem(-12px);
            top: 40%;
            margin-top: rem(-5px);
            transform: rotate(45deg);
        }
    }
}

.list-check, ul.list-check {
    padding-left: 1.25rem;

    ul {
        padding-left: 1.26rem;

    }

    li {
        font-size: inherit;
        line-height: inherit;
        padding-left: 1rem;
        list-style-type: none;
        position: relative;

        &:not(&:nth-last-child(1)) {
            margin-bottom: rem(10px);
        }

        &:before {
            content: "";
            position: absolute;
            left: rem(-12px);
            top: 40%;
            margin-top: rem(-5px);
            width: rem(7px);
            height: rem(14px);
            border: solid $primary;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
}
